<template>
  <div class="Awarp">
    <dashCard
      class="carbonBox10"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('lvscBox25_connectStatus','设备连接状态', getZEdata)}}</template>
      <template slot="aside"> </template>
      <div class="card-content2">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox10
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "carbonBox10",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      timetType: [
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      newarr: [],
      sum: "",
      panelOpen: false,
      searchObj: {
        TYPE: "按月", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      chartOption1: {},
      detailInfo: {
        chart1: {},
      },
      options: [
        {
          value: "1",
          label: "第一季度",
        },
        {
          value: "2",
          label: "第二季度",
        },
        {
          value: "3",
          label: "第三季度",
        },
        {
          value: "4",
          label: "第四季度",
        },
      ],
      quarter: false,
      value: "1",
      colorList: ["#2A35FF", "#4C80EE", "#B4D0FF"],
    };
  },
  computed: {
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 改变类型
    changeType(type) {
      if (type == "按季") {
        this.handleChange();
      }
      this.searchObj.TYPE = type;
      this.getData();
    },
    handleChange(value) {
      if (!!value) this.value = value;
      this.getData();
    },
    getTimes(value) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      let obj = {
        startTime: "",
        endTime: "",
      };
      if (value == "1") {
        obj.startTime = year + "-01-01 00:00:00";
        obj.endTime = year + "-03-31 23:59:59";
      } else if (value == "2") {
        obj.startTime = year + "-04-01 00:00:00";
        obj.endTime = year + "-06-30 23:59:59";
      } else if (value == "3") {
        obj.startTime = year + "-07-01 00:00:00";
        obj.endTime = year + "-09-30 23:59:59";
      } else if (value == "4") {
        obj.startTime = year + "-10-01 00:00:00";
        obj.endTime = year + "-12-31 23:59:59";
      }
      return obj;
    },
    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      this.panelOpen = false;
      this.getData();
    },
    onChange(value) {
      //console.log("onChange", value, this.searchObj);
      this.getData();
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    //每月报警预警数
    getData() {
      const url = Config.dev_url + "/api-apps-v2/api/v2/equipment/status_stat";
      let data = {
        deptCode: this.currentSelectDeptInfo.CODE,
        // starttime:'2023-01-01 00:00:00',
        // endtime:'2023-12-31 00:00:00'
      };
      // if (this.searchObj.TYPE == "按季") {
      //   Object.assign(data, this.getTimes(this.value));
      // } else {
      //   Object.assign(data, this.transformSearch());
      // }
      // this.isLoading = true
      this.$axios.post(url, data).then((res) => {
        //console.log(res);
        this.newarr = res.data.data;
        // //console.log(this.newarr);
        var chart1 = { seriesData: [] };
        chart1.seriesData = [
          {
           name: this.commonLangHandler('lvscBox25_online' , '在线', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            value: this.newarr.online,
          },
          {
            name: this.commonLangHandler('lvscBox25_offline' , '离线', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            value: this.newarr.offline,
          },
          {
            name: this.commonLangHandler('lvscBox23_failure' , '故障', JSON.parse(sessionStorage.getItem('currentDataAll'))),
            value: this.newarr.hitch,
          },
        ];
        this.detailInfo.chart1 = chart1;
        this.sum = this.newarr.count;
        this.initEchart();
      });
      this.isLoading = false;
    },
    initEchart() {
      var { seriesData } = this.detailInfo.chart1;
      var colorList = this.colorList;
      var option = {
        animation: false,
        layoutAnimation: false,
        title: [
          {
            text: "",
            subtext: this.sum,
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            subtextStyle: {
              fontSize: 24,
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#1D2129",
              fontWeight: "600",
            },
            textAlign: "center",

            x: "44%",
            y: "35%",
          },
          {
            text: this.LangHandler('unittotal','总计'),
            textStyle: {
              fontSize: 12,
              color: "#86909c",
              fontFamily: "ABBvoice_WCNSG_Rg",
            },
            textAlign: "center",

            x: "44%",
            y: "48%",
          },
        ],
        tooltip: {
          trigger: "item",
        },
        legend: {
          right: "0%",
          top: "30%",
          orient: "vertical",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
        },
        series: [
          {
            itemStyle: {
              normal: {
                color: function (params) {
                  return colorList[params.dataIndex];
                },
                borderColor: "#172659",
                fontFamily: "ABBvoice_WCNSG_Rg",
                borderWidth: 0,
              },
            },
            type: "pie",
            radius: ["70%", "80%"],
            center: ["45%", "50%"],
            labelLine: {
              normal: {
                lineStyle: {
                  color: "#34569D",
                },
              },
            },
            label: {
              normal: {
                show: false,
                formatter: (params) => {
                  return (
                    "{name|" +
                    params.name +
                    "}{percent|" +
                    params.percent.toFixed(0) +
                    "%}"
                  );
                },
                padding: [0, -5, 0, -5],
              },
            },
            data: seriesData,
          },
        ],
      };
      this.$nextTick(() => {
        this.updateChart("chart1", "chartOption1", option);
      });
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox10 {
  .card-content1 {
    display: flex;
  }
  .card-content2 {
    flex: 1;
    height: 100%;
    div {
      width: 100%;
      height: 100%;
    }
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>
